<template>
  <HpSidebar :id="id" title="Drug cost breakdown" icon="pill">
    <aside class="drug-breakdown-sidebar">
      <div class="drug-breakdown-header">
        <div class="drug-breakdown-total-container">
          <div class="drug-breakdown-total-label">
            <h2 class="text-xl">All Drugs Costs</h2>
            <div>(yearly)</div>
          </div>
          <div class="drug-breakdown-total-value">
            {{ $formatUSD(quote.drugCost) }}
          </div>
        </div>
        <div class="drug-breakdown-data-headers">
          <div>Month</div>
          <div>Cost</div>
        </div>
      </div>
      <div class="drug-breakdown-accordion">
        <HpAccordion>
          <HpAccordionItem
            v-for="month in monthlyCosts"
            :key="month.monthName"
            :title="month.monthName"
          >
            <template #header-detail>
              <div class="monthly-total">{{ $formatUSD(month.total) }}</div>
            </template>
            <div class="month-drug-list">
              <div class="month-drug-item" v-for="drug in month.drugs">
                <div class="month-drug-label">
                  {{ drug.name }}
                </div>
                <div class="month-drug-cost">
                  {{ $formatUSD(drug.cost) }}
                </div>
              </div>
            </div>
          </HpAccordionItem>
        </HpAccordion>
      </div>
      <div class="coverage-period-list">
        <div class="coverage-period-title">{{ quote.details.year === 2025 ? '3 Coverage Periods' : '4 Coverage Periods' }}</div>
        <div class="coverage-period-tile">
          <div class="coverage-period-tile-title">
            Annual Deductible
            <div class="coverage-period-tile-data">
              <div class="coverage-period-tile-label">Limit:</div>
              <div class="coverage-period-tile-value">
                {{ $formatUSD(quote.details.drugDeductible) }}
              </div>
            </div>
          </div>
          <div class="coverage-period-tile-description">
            {{ content.ext.annual_deductible_explanation }}
          </div>
        </div>
        <div class="coverage-period-tile tile-even">
          <div class="coverage-period-tile-title">
            Initial
            <div class="coverage-period-tile-data">
              <div class="coverage-period-tile-label">Limit:</div>
              <div class="coverage-period-tile-value">
                {{ $formatUSD(quote.details.initialCoverageLimit) }}
              </div>
            </div>
          </div>
          <div class="coverage-period-tile-description">
            {{ content.ext.initial_explanation }}
          </div>
        </div>
        <div class="coverage-period-tile" v-if="quote.details.year !== 2025">
          <div class="coverage-period-tile-title">
            Gap (or Donut Hole)
            <div class="coverage-period-tile-data">
              <div class="coverage-period-tile-label">Limit:</div>
              <div class="coverage-period-tile-value">
                {{ $formatUSD(quote.details.catastrophicLimit) }}
              </div>
            </div>
          </div>
          <div class="coverage-period-tile-description">
            {{ content.ext.donut_hole_explanation }}
          </div>
        </div>
        <div class="coverage-period-tile tile-even">
          <div class="coverage-period-tile-title">
            Catastrophic
            <div class="coverage-period-tile-data">
              <div class="coverage-period-tile-label">Limit:</div>
              <div class="coverage-period-tile-value plain-text">End of plan year</div>
            </div>
          </div>
          <div class="coverage-period-tile-description" v-if="quote.details.year !== 2025">
            {{
              hydratePlaceholders(content.ext.catastrophic_explanation, {
                catastrophic_limit: $formatUSD(quote.details.catastrophicLimit)
              })
            }}
          </div>
          <div class="coverage-period-tile-description" v-else>
            {{ content.ext.catastrophic_explanation_2025 }}
          </div>
        </div>
      </div>
    </aside>
  </HpSidebar>
</template>

<script lang="ts" setup>
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const monthlyCosts = computed(
    () =>
      props.quote?.drugCoverage?.pharmacyCosts?.[0].monthlyCosts?.map((m) => ({
        monthName: m.monthName,
        total: _sumBy(m.details, (v) => v.memberCost ?? 0),
        drugs:
          m.details?.map((d) => ({
            name: props.quote.drugCoverage.coverageSummaries.find(
              (cs) => cs.externalId === d.externalId
            )?.drugName,
            cost: d.memberCost
          })) ?? []
      })) ?? []
  )

  const { getComponentContent, hydratePlaceholders } = Cms.use()

  const { content } = await getComponentContent('DrugMonthlyBreakdownSidebar')
</script>

<style scoped>
  .drug-breakdown-sidebar {
    @apply flex flex-col;

    .drug-breakdown-header {
      @apply flex flex-col items-center justify-center space-y-4 pt-8;

      .drug-breakdown-total-container {
        @apply bg-primary-100 rounded-push-button flex w-4/5 flex-col items-center justify-center p-4;

        .drug-breakdown-total-label {
          @apply flex flex-col items-center;
        }

        .drug-breakdown-total-value {
          @apply text-2xl font-bold;

          &:first-letter {
            @apply align-super text-base;
          }
        }
      }

      .drug-breakdown-data-headers {
        @apply rounded-heading flex w-full justify-between bg-gray-200 px-8 py-2 text-lg font-semibold;
      }
    }

    .drug-breakdown-accordion {
      @apply mb-4;

      :deep(.disclosure-outer) {
        @apply border-border-light rounded-none border-0 border-b;

        .disclosure-header-detail {
          @apply text-primary-900;
        }

        .disclosure-panel {
          @apply p-1;
        }
      }

      .monthly-total {
        @apply text-xl font-semibold;
      }

      .month-drug-list {
        @apply flex flex-col space-y-3;

        .month-drug-item {
          @apply flex justify-between pl-4 pr-9 text-lg text-gray-900;

          .month-drug-label {
            @apply xs:mr-16 mr-10 break-all leading-normal;
          }

          .month-drug-cost {
          }
        }
      }
    }

    .coverage-period-list {
      @apply flex flex-col;

      .coverage-period-title {
        @apply rounded-heading bg-gray-200 p-4 text-lg font-semibold;
      }

      .coverage-period-tile {
        @apply flex flex-col space-y-2 border-b border-gray-200 p-4;

        &.tile-even {
        }

        & .coverage-period-tile-title {
          @apply text-primary-900 flex w-full justify-between text-lg font-semibold;
        }

        & .coverage-period-tile-description {
          @apply text-gray-800;
        }

        & .coverage-period-tile-data {
          @apply text-primary-900 flex items-baseline space-x-2;

          & .coverage-period-tile-label {
            @apply text-lg font-semibold;
          }

          & .coverage-period-tile-value {
            @apply text-xl font-bold;
          }
        }
      }
    }
  }
</style>
